img {
    filter: invert(100%) sepia(62%) saturate(0%) hue-rotate(29deg) brightness(105%) contrast(103%);
    width: 5em;
}

img:hover {
    background-color: #17182f;
}

.SectionList {

}

.list-vertical {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.list-vertical > * {
    text-align: center;
}

.list-horizontal {
    display: flex;
    align-items: end;
}

@media only screen and (max-width: 899px) {
    .list-horizontal {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
