a {
    color: white;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.button-link-rounded {
    display: block;
    background-color: #212245;
    padding: .25em .75em;
    margin: .6em 1.2em;
    border-radius: 0.2em;
    transition: all .1s ease-out 0ms;
    -webkit-transition: all .1s ease-out 0ms;
    -moz-transition: all .1s ease-out 0ms;
    -o-transition: all .1s ease-out 0ms;
}

.button-link-rounded:hover {
    transform: scale(1.2);
    background-color: #282951;
}
